<template>
  <div class="new-detail">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>{{ $t('隐私政策') }}</span>
      <span></span>
    </header>
    <div class="article">
      <div class="img"><img :src="CompanyPresentationImg" /></div>
      <div class="title">隐私政策说明</div>
      <div class="content">
        <p>
          <van-button type="danger" @click="onRemove" block
            >删除账户</van-button
          >
        </p>
        <p>
          {{ CompanyName }}
          尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，
          {{ CompanyName }}
          会按照本隐私权政策的规定使用和披露您的个人信息。但
          {{ CompanyName }}将以高度的勤勉、
          审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，
          {{ CompanyName }} 不会将这些信息对外披露或向第三方提供。
          {{ CompanyName }}会不时更新本隐私权政策。 您在同意{{
            CompanyName
          }}
          服务使用协议之时，即视为您已经同意本隐私权政策全部内容。
          本隐私权政策属于{{ CompanyName }}
          服务使用协议不可分割的一部分。
        </p>
        <p>
          适用范围<br />
          (a) 在您注册{{ CompanyName }} 帐号时，您根据{{
            CompanyName
          }}
          要求提供的个人注册信息；<br />

          (b) 在您使用{{ CompanyName }} 网络服务，或访问{{ CompanyName }}
          平台网页时，
          {{ CompanyName }}
          自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、
          浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；<br />

          (c) {{ CompanyName }}
          通过合法途径从商业伙伴处取得的用户个人数据。
        </p>
        <p>
          您了解并同意，以下信息不适用本隐私权政策：<br />

          (a) 您在使用{{
            CompanyName
          }}
          平台提供的搜索服务时输入的关键字信息；<br />

          (b) {{ CompanyName }} 收集到的您在{{
            CompanyName
          }}
          发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；<br />

          (c) 违反法律规定或违反{{ CompanyName }} 规则行为及{{
            CompanyName
          }}
          已对您采取的措施。<br />
        </p>
        <p>
          信息使用<br />
          (a){{
            CompanyName
          }}
          不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和{{
            CompanyName
          }}（含{{ CompanyName }}
          关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。
          <br />
          (b)
          {{ CompanyName }}
          亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何{{
            CompanyName
          }}平台用户如从事上述活动， 一经发现，{{
            CompanyName
          }}有权立即终止与该用户的服务协议。
          <br />
          (c) 为服务用户的目的，{{
            CompanyName
          }}
          可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与{{
            CompanyName
          }}
          合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。
        </p>
        <p>
          信息披露<br />
          在如下情况下，{{ CompanyName }}
          将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
          <br />
          (a) 经您事先同意，向第三方披露；<br />

          (b)为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；<br />

          (c)
          根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；<br />

          (d) 如您出现违反中国有关法律、法规或者{{
            CompanyName
          }}
          服务协议或相关规则的情况，需要向第三方披露；<br />

          (e)
          如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；<br />

          (f) 在{{
            CompanyName
          }}
          平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，{{
            CompanyName
          }}
          有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。
          <br />
          (g) 其它{{ CompanyName }}
          根据法律、法规或者网站政策认为合适的披露。
        </p>
        <p>
          信息存储和交换<br />
          {{ CompanyName }} 收集的有关您的信息和资料将保存在{{
            CompanyName
          }}
          及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或{{
            CompanyName
          }}
          收集信息和资料所在地的境外并在境外被访问、存储和展示。
          <br />
          Cookie的使用<br />
          (a) 在您未拒绝接受cookies的情况下，{{
            CompanyName
          }}
          会在您的计算机上设定或取用cookies
          ，以便您能登录或使用依赖于cookies的{{
            CompanyName
          }}
          平台服务或功能。{{ CompanyName }}
          使用cookies可为您提供更加周到的个性化服务，包括推广服务。
          <br />
          (b)
          您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，
          则您可能无法登录或使用依赖于cookies的{{ CompanyName }}网络服务或功能。
          <br />
          (c) 通过{{ CompanyName }}
          所设cookies所取得的有关信息，将适用本政策。
        </p>
        <p>
          信息安全<br />
          (a)
          {{ CompanyName }}
          帐号均有安全保护功能，请妥善保管您的用户名及密码信息。
          {{ CompanyName }}
          将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。
          <br />
          (b) 在使用{{ CompanyName }}
          网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对
        </p>
        <p>
          7.本隐私政策的更改<br />

          (a)如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。
          <br />
          (b)本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知。
          <br />
          方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，
          尤其是 用户名及密码发生泄露，请您立即联络
          {{ CompanyName }}客服，以便{{ CompanyName }} 采取相应措施。
        </p>
        
       
      </div>
    </div>
  </div>
</template>
<script>
import { Dialog } from 'vant';
import { BASE_URL } from '@/api/index.js';
import { getFirmInfo } from '@/api/website.js';
import { removeAccount } from '@/api/account.js';
 
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      CompanyName: '',
      CompanyObligation: '',
      CompanyPhone: '',
      CompanyPresentationImg: '',
      CompanyQQ: '',
      CompanyResume: '',
      CompanySummary: '',
      CompanyTel: '',
      CompanyVision: '',
      CompanyWechat: '',
      WechatPicUrl: '',
      CompanyMission: '',
      CompanyMail: '',
      CompanyLinkMan: '',
      CompanyAddr: '',
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {},
  methods: {
    htmlDecode(text) {
      //1.首先动态创建一个容器标签元素，如DIV
      var temp = document.createElement('div');
      //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
      temp.innerHTML = text;
      //3.最后返回这个元素的innerText(ie支持)或者textContent(火狐，google支持)，即得到经过HTML解码的字符串了。
      var output = temp.innerText || temp.textContent;
      temp = null;
      console.log(output);
      return output;
    },
    onRemove() {
      Dialog.confirm({
        title: '删除账户',
        message: '删除账号意味着您的数据从系统全部删除，您确定要删除账户？',
      })
        .then(() => {
          // on confirm
          removeAccount().then(res=>{
            if(res.Result==true){
              setTimeout(()=>{this.$router.replace({ name: 'Login' });},1500);
            }
            this.$toast(res.Info);
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    loadData() {
      getFirmInfo().then(res => {
        if (res.Result == true) {
          let data = res.DataDict['data'];
          console.log(res);
          this.CompanyName = data.CompanyName;
          this.CompanyObligation = data.CompanyObligation;
          this.CompanyPhone = data.CompanyPhone;
          if (data.CompanyPresentationImg.startsWith('http')) {
            this.CompanyPresentationImg = data.CompanyPresentationImg;
          } else {
            this.CompanyPresentationImg =
              BASE_URL + data.CompanyPresentationImg;
          }
          this.CompanyQQ = data.CompanyQQ;
          this.CompanyResume = data.CompanyResume;
          this.CompanySummary = data.CompanySummary;
          this.CompanyTel = data.CompanyTel;
          this.CompanyVision = data.CompanyVision;
          this.CompanyWechat = data.CompanyWechat;
          if (data.WechatPicUrl.startsWith('http')) {
            this.WechatPicUrl = data.WechatPicUrl;
          } else {
            this.WechatPicUrl = BASE_URL + data.WechatPicUrl;
          }
          this.CompanyMission = data.CompanyMission;
          this.CompanyMail = data.CompanyMail;
          this.CompanyLinkMan = data.CompanyLinkMan;
          this.CompanyAddr = data.CompanyAddr;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/_var';
.article {
  margin: rem(20px);
  margin-top: rem(100px);
  line-height: 0.6rem;
  background: #fff;
  max-width: 100%;
  padding: rem(20px);
  .title {
    font-size: rem(40px);
    text-align: left;
    padding: rem(15px);
  }
  .img img {
    width: 100%;
    height: rem(260px);
    border: 1px solid #efefef;
    border-radius: 10px;
  }
  .content {
    img {
      max-width: 100%;
    }
  }
  .author {
    font-size: rem(18px);
    padding: rem(15px);
  }
  img {
    max-width: 100%;
  }
}
</style>
